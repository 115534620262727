// Based on https://hankchizljaw.com/wrote/create-a-user-controlled-dark-or-light-mode/
import { send } from "../lib/events";

const STORAGE_KEY = "user-color-scheme";

function currentSystemColorScheme() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
}

export default class ThemeToggle extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.addEventListener("toggle-theme", this.toggle, false);

    this.toggle = this.querySelector("button");
    this.toggle.addEventListener("click", this.click);

    this.icon = this.querySelector("svg");
    if (this.icon) {
      // Delay should match var(--delay-m)
      setTimeout(() => {
        delete this.icon.dataset.loading;
      }, 300);
    }

    this.applySetting();
  }

  disconnectedCallback() {
    this.removeEventListener("toggle-theme", this.toggle);
    this.toggle.removeEventListener("click", this.click);
  }

  click(e) {
    const event = new CustomEvent("toggle-theme", { bubbles: true });
    e.currentTarget.dispatchEvent(event);
  }

  toggle(e) {
    e.preventDefault();

    const setting = this.toggleSetting();
    this.applySetting(setting);
  }

  toggleSetting() {
    const currentSetting =
      localStorage.getItem(STORAGE_KEY) ?? currentSystemColorScheme();
    const nextSetting = currentSetting === "light" ? "dark" : "light";

    localStorage.setItem(STORAGE_KEY, nextSetting);

    send("Toggle Theme", { theme: nextSetting });

    return nextSetting;
  }

  applySetting(setting) {
    const currentSetting = setting || localStorage.getItem(STORAGE_KEY);

    if (currentSetting) {
      document.documentElement.setAttribute(
        "data-user-color-scheme",
        currentSetting
      );
      this.setButtonLabelAndStatus(currentSetting);
    }
  }

  setButtonLabelAndStatus(setting) {
    this.dataset.colorScheme = setting;

    const ariaLabel = setting ? `${setting} mode` : "use system preference";
    this.setAttribute("aria-label", ariaLabel);
  }
}

customElements.define("theme-toggle", ThemeToggle);
